import React from "react";
import logo from "../../../images/dementia-logo-removebg-preview.png"; // Adjust the path as needed
import profileImage from "../../../images/profile-image.png"; // Adjust the path as needed
import homeIcon from "../../../images/icon-camera.png";

const HeaderComponent = ({ name, image = '' }) => {
  const topValue = name ? "-7%" : "-2%";
  return (
    <header className="flex items-center justify-between px-6 mt-4 mb-4">
      <div className="flex flex-col items-center space-y-2">
        <img src={logo} alt="6D Dementia Training" className="w-24" />
        {name && (
          <>
            <img
              src={image ? image : profileImage}
              alt="Profile"
              className="w-12 h-12 rounded-full"
            />
            <span className="text-blue-600">Welcome, {name}</span>
          </>
        )}
      </div>
      <h1
        className="text-3xl font-medium text-center text-customBlue flex-grow"
        style={{ marginTop: topValue }}
      >
        6D Dementia Training
      </h1>
      <img
        src={homeIcon}
        alt="Home"
        className="w-12 h-12"
        style={{ marginTop: topValue }}
      />
    </header>
  );
};

export default HeaderComponent;
