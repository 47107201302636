import React, { useEffect } from "react";

import { userInfo } from "../../apiFunction/ApiFunctions";
import { useLocation } from "react-router-dom";
import HeaderComponent from "../admin/shared/HeaderComponent";
import FooterComponent from "../admin/shared/FooterComponent";

const UserCompletedTraining = () => {
  // const users = userInfo("user", 1000);
  const location = useLocation();
  const { state } = location;

  useEffect(() => {
    // setCustomers(customerList());
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />

      <div className="bg-customBlue w-full text-center text-white">
        <h2 className="text-5xl mb-8 mt-4">Course: 6D Training for Teams</h2>
        <p className="text-4xl font-light mb-4">Members who have completed Training</p>
      </div>
      <main className="w-full p-6 flex flex-col items-center">
        <div className="w-full max-w-8xl rounded-lg p-8 space-y-6 ml-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 w-full">
            {state.users.map((user, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center"
              >
                <img
                  src={`https://66-dementia.b-cdn.net/test/users/pictures/${user.user_id}`}
                  alt={user.user_name}
                  className="w-32 h-32 rounded-full mb-4"
                />
                <h3 className="text-xl font-semibold mb-2">
                  {user.user_name}
                </h3>
                {/* <p className="text-gray-600 mb-4">{user.user_name}</p> */}
                {/* <button className="w-full bg-blue-950 text-white py-2 px-6 rounded-3xl">
                  View Details
                </button> */}
              </div>
            ))}
          </div>
        </div>
      </main>

      <FooterComponent path={-1} />
    </div>
  );
};

export default UserCompletedTraining;
