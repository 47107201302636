import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../user/Header";
import organisationIcon from "../../images/organisations.png";
import supportingMaterialsIcon from "../../images/supporting-materials-icon.png";
import financeIcon from "../../images/finance-icon.png";
import teamIcon from "../../images/teams.png";
import userIcon from "../../images/users.png";
import {
  customerList,
  getCourseCompletionsByOrganisation,
  getUpcomingWebinars,
  getUpcomingWebinarsByOrganisation,
  getWebinars,
} from "../../apiFunction/ApiFunctions";

const ManagerHomeScreen = () => {
  const navigate = useNavigate();
  const [activeTrainingCourses, setActiveTrainingCourses] = useState(null);
  const [upcomingLiveWebinars, setUpcomingLiveWebinars] = useState(null);
  const [isCoursesLoading, setIsCoursesLoading] = useState(true);
  const [isWebinarsLoading, setIsWebinarsLoading] = useState(true);
  const user = JSON.parse(sessionStorage.getItem("userdetails"));
  const customers = customerList();
  // const activeTrainingCourses = [
  //   {
  //     course_name: "6D Training for Teams",
  //     percent_complete: 80,
  //   },
  //   {
  //     course_name: "6D Specialist Team Training",
  //     percent_complete: 50,
  //   },
  //   {
  //     course_name: "6D Advanced Team Training",
  //     percent_complete: 30,
  //   },
  // ];

  // const upcomingLiveWebinars = [
  //   {
  //     name: "Session 1: Understanding Symptoms",
  //     date: "20th Nov 2024",
  //   },
  //   {
  //     name: "Session 2: Care Techniques",
  //     date: "11th Dec 2024",
  //   },
  //   {
  //     name: "Session 3: Managing Stress",
  //     date: "19th Dec 2024",
  //   },
  // ];

  useEffect(() => {
    getUpcomingWebinarsByOrganisation()
      .then((data) => {
        setIsWebinarsLoading(false);
        console.log("webinars", data); // Access the responseData returned from the function
        setUpcomingLiveWebinars(data);
        // setWebinars(data);
      })
      .catch((error) => {
        console.error("Error fetching webinars:", error);
      });

    getCourseCompletionsByOrganisation()
      .then((data) => {
        setIsCoursesLoading(false);
        console.log("courses", data); // Access the responseData returned from the function
        setActiveTrainingCourses(data);
      })
      .catch((error) => {
        console.error("Error fetching courses:", error);
      });
  }, []);

  return (
    <div className="min-h-screen bg-white p-4 md:p-8">
      <Header />
      <div className=" grid grid-cols-1 lg:grid-cols-6 gap-12">
        <div className="flex flex-col items-center">
          <h3 className="text-lg font-semibold text-[#0089ad]">
            Welcome, {user.name}
          </h3>
          <img
            // src={require("../../images/profile-image.png")}
            src={`https://66-dementia.b-cdn.net/test/users/pictures/${user.id}`}
            alt="Profile"
            className="mt-6 h-24 w-24 rounded-lg object-cover"
          />
        </div>

        <div className="lg:col-span-3 2xl:col-span-4">
          <div className="bg-white p-4 rounded-lg shadow">
            <h2 className="text-2xl font-semibold mb-4">
              Active Training Courses
            </h2>
            <div className="space-y-4">
              {isCoursesLoading && (
                <div role="status" className="ml-[50%]">
                  <svg
                    aria-hidden="true"
                    className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              {activeTrainingCourses &&
                activeTrainingCourses.map((course, index) => (
                  <div key={index}>
                    <div className="flex justify-between">
                      <h3 className="text-2xl">{course.course_name}</h3>
                      <span className="text-lg text-gray-600">
                        {Math.round(course.percent_complete)}% Complete
                      </span>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-2.5 mb-8">
                      <div
                        className="bg-green-500 h-2.5 rounded-full"
                        style={{ width: `${course.percent_complete}%` }}
                      ></div>
                    </div>
                  </div>
                ))}
            </div>
            {!isCoursesLoading && (
              <div className="text-center">
                <button
                  className="mt-6 mb-2 px-10 py-2 bg-blue-900 text-white rounded-3xl shadow hover:bg-blue-700"
                  onClick={() => {
                    // navigate("/managerdashboard", {
                    //   state: {
                    //     courses: activeTrainingCourses,
                    //   },
                    // });
                    navigate("/courseprogress", {
                      state: {
                        courses: activeTrainingCourses,
                      },
                    });
                  }}
                >
                  View
                </button>
              </div>
            )}
          </div>

          <div className="bg-white p-4 rounded-lg shadow mt-6">
            <h2 className="text-2xl font-semibold mb-4">
              Upcoming Live Webinars
            </h2>
            <ul className="space-y-4">
              {isWebinarsLoading && (
                <div role="status" className="ml-[50%]">
                  <svg
                    aria-hidden="true"
                    className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              {upcomingLiveWebinars &&
                upcomingLiveWebinars.map((session, index) => (
                  <li
                    key={session.id}
                    className="flex items-center justify-between"
                  >
                    <span className="text-2xl flex items-center ">
                      <img
                        src={`https://66-dementia.b-cdn.net/test/webinars/images/${session.id}`}
                        alt="Training Courses"
                        className="w-12 h-12 rounded-3xl mr-4"
                      />
                      Session {index + 1} : {session.title}
                    </span>
                    <span className="text-2xl">
                      {new Date(session.date_time).toLocaleDateString()}
                    </span>
                  </li>
                ))}
            </ul>
            {!isWebinarsLoading && upcomingLiveWebinars.length !== 0 && (
              <div className="text-center">
                <button
                  className="mt-6 mb-2 px-10 py-2 bg-blue-900 text-white rounded-3xl shadow hover:bg-blue-700"
                  onClick={() => {
                    navigate("/upcomingtrainingwebinars", {
                      state: {
                        webinars: upcomingLiveWebinars,
                      },
                    });
                  }}
                >
                  View
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="space-y-20 lg:col-span-2 2xl:col-span-1">
          <button
            className="bg-white rounded-2xl shadow-lg p-6 flex items-center space-x-4 hover:bg-gray-100 flex-shrink-0 w-full h-20"
            onClick={() =>
              navigate("/organisationdashboard", {
                state: {
                  name: customers[0].name,
                  image: customers[0].logo,
                  address: customers[0].address,
                },
              })
            }
          >
            <img src={organisationIcon} alt="Customers" className="w-10" />
            <h2 className="text-lg text-gray-700">Organisation</h2>
          </button>
          <button
            className="bg-white rounded-2xl shadow-lg p-6 flex items-center space-x-4 hover:bg-gray-100 flex-shrink-0 w-full h-20"
            onClick={() => {
              navigate("/teams");
            }}
          >
            <img src={teamIcon} alt="Training Courses" className="w-10" />
            <h2 className="text-lg text-gray-700">Teams</h2>
          </button>
          <button
            className="bg-white rounded-2xl shadow-lg p-6 flex items-center space-x-4 hover:bg-gray-100 flex-shrink-0 w-full h-20"
            onClick={() => {
              navigate("/userlist");
            }}
          >
            <img src={userIcon} alt="Training Courses" className="w-10" />
            <h2 className="text-lg text-gray-700">Users</h2>
          </button>
          <button
            className="bg-white rounded-2xl shadow-lg p-6 flex items-center space-x-4 hover:bg-gray-100 flex-shrink-0 w-full h-20"
            onClick={() => {
              navigate("/subscription", {
                state: {
                  name: customers[0].name,
                  image: customers[0].logo,
                  address: customers[0].address,
                },
              });
            }}
          >
            <img src={financeIcon} alt="Training Courses" className="w-10" />
            <h2 className="text-lg text-gray-700">Subscriptions</h2>
          </button>
          <button
            className="bg-white rounded-2xl shadow-lg p-6 flex items-center space-x-4 hover:bg-gray-100 flex-shrink-0 w-full h-20"
            onClick={() => {
              navigate("/supportingmaterials");
            }}
          >
            <img
              src={supportingMaterialsIcon}
              alt="Training Courses"
              className="w-10"
            />
            <h2 className="text-lg text-gray-700">Supporting Material</h2>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManagerHomeScreen;
