import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";

const SuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  return (
    <div className="min-h-screen  bg-white py-8">
      <div className="container mx-auto px-4 ">
        <Header />
        <div className="text-center mt-[20%]">
          <div className="flex justify-center mb-4">
            <svg
              className="w-20 h-20 text-blue-900"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm-1-4.293l5.707-5.707-1.414-1.414L9 11.586l-2.293-2.293-1.414 1.414L9 13.707z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <h2 className="text-4xl font-bold mb-4">
            Thank you for registering!
          </h2>
          <p className="text-2xl text-gray-700 mb-6">
            {`You have successfully registered for the Live Training session on
            ${state.title} presented by ${state.presenter} on the ${new Date(
              state.date_time
            ).toLocaleDateString()} at ${new Date(
              state.date_time
            ).toLocaleTimeString()}.
            Stay tuned for further details.`}
          </p>
        </div>
        <div className=" absolute bottom-10 flex justify-center ml-[40%]">
          <button
            className="bg-blue-900 text-white px-10 py-2 rounded-3xl shadow-lg hover:bg-blue-700"
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
