import { Upload } from "tus-js-client";
import React, { useEffect, useState } from "react";
import HeaderComponent from "./shared/HeaderComponent";
import { useLocation, useNavigate } from "react-router-dom";

const UploadVideoPage = () => {
  const location = useLocation();
  const { state } = location;
  // console.log("state", state);s
  //   const [id, setId] = useState(state
  const [file, setFile] = useState(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  //   const [courseList, setCourseList] = useState([]);
  // const [image, setImage] = useState(state ? state.image : null);
  //   const [url, setUrl] = useState(state ? state.url : "");
  // const [updatedFormData, setUpdatedFormData] = useState([]);
  //   // const [dateTime, setDateTime] = useState("");
  //   const [selectedCourse, setSelectedCourse] = useState(
  //     state ? state.selectedCourse : ""
  //   );
  const [loading, setLoading] = useState(false);
  // const [image, setImage] = useState(state ? state.image : "");

  const navigate = useNavigate();

  // const handleThumbnailChange = (e) => {
  //   setImage(URL.createObjectURL(e.target.files[0]));
  // };
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setUploadPercentage(0);
  };

  const handleUpload = () => {
    if (!file) {
      alert("Please select a video file.");
      return;
    }

    setIsUploading(true);

    const upload = new Upload(file, {
      endpoint: "https://video.bunnycdn.com/tusupload",
      retryDelays: [0, 3000, 5000, 10000, 20000, 60000],
      headers: {
        AuthorizationSignature: state.response_data.video_upload.signature,
        // AuthorizationSignature: "25d5a47c41eef119b5daf0f559567fea0463df0edbe62e8979d96dae902423b5",
        // AuthorizationExpire: Math.floor(Date.now() / 1000) + 3600, // 1 hour expiration
        AuthorizationExpire: state.response_data.video_upload.expires, // 1 hour expiration
        VideoId: state.response_data.lesson.video_id,
        // VideoId: "abfb7aad-0155-42ca-b94d-b87ffca74d55",
        LibraryId: 324584,
      },
      metadata: {
        filetype: file.type,
        title: file.name,
        collection: "collectionID",
      },
      onError: (error) => {
        console.error("Upload failed:", error);
        setIsUploading(false);
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        setUploadPercentage(((bytesUploaded / bytesTotal) * 100).toFixed(2));
      },
      onSuccess: () => {
        console.log("Upload complete");
        setIsUploading(false);
        navigate("/lesson-list", {
          state: {
            id: state.id,
            title: state.title,
            course_id: state.course_id,
            course_name: state.course_name,
          },
        });
      },
    });

    upload.start();
  };

  //   const handleSubmit = (e) => {
  //     e.preventDefault();
  //     setLoading(true);

  //     // Prepare the form data
  //     const formData = {
  //       id: state ? state.id : null,
  //       title,
  //     };

  //     createModule(formData).then(() => {
  //       navigate("/course-details", {
  //         state: {
  //           id: state.course_id,
  //           name: state.course_name,
  //         },
  //       });
  //       setLoading(false);
  //     });

  //     // Check if any previous data is already there or not
  //     // const sessionTrainingVideo = JSON.parse(
  //     //   sessionStorage.getItem("trainingVideo")
  //     // );

  //     // let updatedFormData;
  //     // if (
  //     //   sessionTrainingVideo !== null &&
  //     //   !Array.isArray(sessionTrainingVideo) &&
  //     //   typeof sessionTrainingVideo === "object"
  //     // ) {
  //     //   updatedFormData = [formData, sessionTrainingVideo];
  //     // } else if (Array.isArray(sessionTrainingVideo)) {
  //     //   updatedFormData = [formData, ...sessionTrainingVideo];
  //     //   console.log(updatedFormData);
  //     // } else {
  //     //   updatedFormData = [formData];
  //     // }

  //     // // Save the form data to session storage
  //     // sessionStorage.setItem("trainingVideo", JSON.stringify(updatedFormData));

  //     // // Log the form data
  //     // console.log("Form Data as JSON:", JSON.stringify(updatedFormData, null, 2));

  //     // // Navigate to homescreen
  //     // navigate("/homescreen");
  //   };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />

      <main className="w-full p-6 flex flex-col items-center">
        {/* <form
          className="w-full max-w-8xl rounded-lg p-8 space-y-6 ml-4"
          onSubmit={handleSubmit}
        > */}
        <h2 className="text-2xl text-customBlue ml-[-1%]">
          Create Video for Lesson: {state.response_data.lesson.title}
        </h2>
        <div className="flex flex-col">
          <label className="text-lg font-medium text-gray-700 mb-2">
            Choose a Video File:
          </label>
          <input
            type="file"
            accept="video/*"
            onChange={handleFileChange}
            className="w-full text-gray-700 px-4 py-2 bg-white border border-gray-300 rounded-lg cursor-pointer"
          />

          {file && (
            <div className="w-full">
              <button
                onClick={handleUpload}
                className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition"
                disabled={isUploading}
              >
                {isUploading ? "Uploading..." : "Upload Video"}
              </button>

              {isUploading && (
                <div className="w-full bg-gray-300 rounded-full h-2 mt-3">
                  <div
                    className="bg-blue-600 h-2 rounded-full transition-all duration-300"
                    style={{ width: `${uploadPercentage}%` }}
                  />
                </div>
              )}

              {uploadPercentage > 0 && (
                <p className="text-sm text-gray-600 mt-1">
                  {uploadPercentage}% uploaded
                </p>
              )}
            </div>
          )}
        </div>

        {/* <div className="flex justify-center items-center">
            <button
              type="submit"
              className="bg-blue-900 text-white py-3 px-6 rounded-lg font-medium w-40 h-12"
            >
              {loading ? (
                <center>
                  <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-white mr-2"></div>
                </center>
              ) : (
                <>{state.id ? "Update" : "Create"}</>
              )}
            </button>
          </div> */}
        {/* </form> */}
      </main>
    </div>
  );
};

export default UploadVideoPage;
