// src/components/CreateWebinarPage.js

import React, { useEffect, useState } from "react";
import HeaderComponent from "./shared/HeaderComponent";
import {
  createCourse,
  createLesson,
  createModule,
  trainingCourses,
} from "../../apiFunction/ApiFunctions";
import { useLocation, useNavigate } from "react-router-dom";

const CreateLessonPage = () => {
  const location = useLocation();
  const { state } = location;
  // console.log("state", state);s
  //   const [id, setId] = useState(state ? state.id : null);
  const [moduleName, setModuleName] = useState(state ? state.module_title : "");
  const [title, setTitle] = useState(state ? state.title : "");
  const [description, setDescription] = useState(
    state ? state.description : ""
  );
  const [author, setAuthor] = useState(state ? state.author : "");
  const [aboutAuthor, setAboutAuthor] = useState(
    state ? state.about_author : ""
  );
  const [durationMinutes, setDurationMinutes] = useState(
    state ? state.duration_minutes : ""
  );
  const [keyTopics, setKeyTopics] = useState(state ? state.key_topics : "");
  const [videoId, setVideoId] = useState(state ? state.video_id : "");

  //   const [courseList, setCourseList] = useState([]);
  const [image, setImage] = useState(state ? state.image : null);
  //   const [url, setUrl] = useState(state ? state.url : "");
  // const [updatedFormData, setUpdatedFormData] = useState([]);
  //   // const [dateTime, setDateTime] = useState("");
  //   const [selectedCourse, setSelectedCourse] = useState(
  //     state ? state.selectedCourse : ""
  //   );
  const [loading, setLoading] = useState(false);
  // const [image, setImage] = useState(state ? state.image : "");

  const navigate = useNavigate();

  // const handleThumbnailChange = (e) => {
  //   setImage(URL.createObjectURL(e.target.files[0]));
  // };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Prepare the form data
    const formData = {
      id: state ? state.id : null,
      module_id: state.module_id,
      title,
      description: description,
      author,
      about_author: aboutAuthor,
      duration_minutes: durationMinutes,
      key_topics: keyTopics.split(", "),
      image: image
        ? image.includes("https://")
          ? ""
          : image.split(",")[1]
        : null,
    };

    createLesson(formData).then((responseData) => {
      console.log("responseData", responseData);
      navigate("/upload-video", {
        state: {
          id: state.module_id,
          title: state.module_title,
          course_id: state.course_id,
          course_name: state.course_name,
          response_data: responseData
        },
      });
      setLoading(false);
    });

    // Check if any previous data is already there or not
    // const sessionTrainingVideo = JSON.parse(
    //   sessionStorage.getItem("trainingVideo")
    // );

    // let updatedFormData;
    // if (
    //   sessionTrainingVideo !== null &&
    //   !Array.isArray(sessionTrainingVideo) &&
    //   typeof sessionTrainingVideo === "object"
    // ) {
    //   updatedFormData = [formData, sessionTrainingVideo];
    // } else if (Array.isArray(sessionTrainingVideo)) {
    //   updatedFormData = [formData, ...sessionTrainingVideo];
    //   console.log(updatedFormData);
    // } else {
    //   updatedFormData = [formData];
    // }

    // // Save the form data to session storage
    // sessionStorage.setItem("trainingVideo", JSON.stringify(updatedFormData));

    // // Log the form data
    // console.log("Form Data as JSON:", JSON.stringify(updatedFormData, null, 2));

    // // Navigate to homescreen
    // navigate("/homescreen");
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />

      <main className="w-full p-6 flex flex-col items-center">
        <form
          className="w-full max-w-8xl rounded-lg px-8 space-y-6 ml-4"
          onSubmit={handleSubmit}
        >
          <h2 className="text-2xl text-customBlue ml-[-1%]">
            {state.id ? "Update" : "Create"} Lesson
          </h2>
          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Module:
            </label>
            <input
              type="text"
              className="border border-gray-300 rounded-lg p-3"
              placeholder="Name of Live Webinar"
              value={state.module_title}
              required
              readOnly
            />
          </div>

          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Title:
            </label>
            <input
              type="text"
              className="border border-gray-300 rounded-lg p-3"
              placeholder="Add a Lesson Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>

          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Description:
            </label>
            <textarea
              className="border border-gray-300 rounded-lg p-3"
              placeholder="Description of the Lesson"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>

          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Author:
            </label>
            <input
              type="text"
              className="border border-gray-300 rounded-lg p-3"
              placeholder="Name of The Author"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
              required
            />
          </div>

          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              About Author:
            </label>
            <input
              type="text"
              className="border border-gray-300 rounded-lg p-3"
              placeholder="Write something about the Author"
              value={aboutAuthor}
              onChange={(e) => setAboutAuthor(e.target.value)}
              required
            />
          </div>

          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Duration:
            </label>
            <input
              type="text"
              className="border border-gray-300 rounded-lg p-3"
              placeholder="Duration in Minutes"
              value={durationMinutes}
              onChange={(e) => setDurationMinutes(e.target.value)}
              required
            />
          </div>

          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Key Topics:
            </label>
            <input
              type="text"
              className="border border-gray-300 rounded-lg p-3"
              placeholder="Key topics of the Lesson"
              value={keyTopics}
              onChange={(e) => setKeyTopics(e.target.value)}
              required
            />
          </div>

          {/* <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Video Id:
            </label>
            <input
              type="text"
              className="border border-gray-300 rounded-lg p-3"
              placeholder="Name of Live Webinar"
              value={videoId}
              onChange={(e) => setVideoId(e.target.value)}
              required
            />
          </div> */}

          <div className="w-1/6 flex flex-col items-center">
            <div className="mb-4">
              {image ? (
                <img
                  src={image}
                  alt="Logo"
                  className="w-32 h-32 rounded-full object-cover"
                />
              ) : (
                <div className="w-32 h-32 rounded-full bg-gray-200 flex items-center justify-center">
                  <span className="text-gray-500">Upload image</span>
                </div>
              )}
            </div>
            <label
              htmlFor="logo-upload"
              className="bg-customBlue text-white px-4 py-2 rounded-lg cursor-pointer"
            >
              Upload Thumbnail
            </label>
            <input
              id="logo-upload"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="hidden"
            />
          </div>

          <div className="flex justify-center items-center">
            <button
              type="submit"
              className="bg-blue-900 text-white py-3 px-6 rounded-lg font-medium w-64 h-12"
            >
              {loading ? (
                <center>
                  <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-white mr-2"></div>
                </center>
              ) : (
                <>{state.id ? "Update and Change Video" : "Create and Add Video"}</>
              )}
            </button>
          </div>
        </form>
      </main>
    </div>
  );
};

export default CreateLessonPage;
