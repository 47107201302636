import React, { useEffect, useState } from "react";
import HeaderComponent from "./shared/HeaderComponent";
import FooterComponent from "./shared/FooterComponent";
import { getUserWebinarsByWebinar } from "../../apiFunction/ApiFunctions";
import { useLocation } from "react-router-dom";

const WebinarRegistrationListPage = () => {
  const env = process.env.REACT_APP_ENV;
  const [users, setUsers] = useState([]);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const { state } = location;

  useEffect(() => {
    getUserWebinarsByWebinar(state.id).then((data) => {
      setUsers(data);
      setLoading(false);
    });
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />

      <div className="bg-customBlue w-full text-center text-white">
        <h2 className="text-3xl mb-2 mt-4">Webinar Registration</h2>
        <p className="font-medium ">{state.title}</p>
        <p>Presented by {state.presentedBy}</p>
        <p className="mb-4">on {state.date_time}</p>
      </div>
      <main className="w-full p-6 flex flex-col items-center">
        <div className="w-full max-w-8xl rounded-lg p-8 space-y-6 ml-4">
          {loading ? (
            // Show loading icon while fetching data
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 w-full">
              {users.map((user, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center"
                >
                  <img
                    src={`https://66-dementia.b-cdn.net/${env}/users/pictures/${user.user_id}`}
                    alt={user.user_name}
                    className="w-32 h-32 rounded-full mb-4"
                  />
                  <h3 className="text-xl font-semibold mb-2">
                    {user.user_name}
                  </h3>
                  <p className="text-gray-600 mb-4">{user.user_roles[0]}</p>
                  {/* <button className="w-full bg-blue-950 text-white py-2 px-6 rounded-3xl">
                  View Details
                </button> */}
                </div>
              ))}
            </div>
          )}
          {!users.length && !loading && (
            <div className="flex justify-center text-2xl">No User is Registered!</div>
          )}
        </div>
      </main>

      <FooterComponent path={-1} />
    </div>
  );
};

export default WebinarRegistrationListPage;
