import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getCourseCompletionsByLocation,
  getUpcomingWebinarsByLocation,
} from "../../apiFunction/ApiFunctions";
import FooterComponent from "../admin/shared/FooterComponent";
import HeaderComponent from "../admin/shared/HeaderComponent";

const AdministratorProfile = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const { state } = location;
  const { admin, locations } = state;
  const [isWebinarsLoading, setIsWebinarsLoading] = useState(true);
  const [isCoursesLoading, setIsCoursesLoading] = useState(true);
  const [courses, setCourses] = useState(null);
  const [webinars, setWebinars] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(locations[0]);

  useEffect(() => {
    setIsCoursesLoading(true);
    setIsWebinarsLoading(true);

    getCourseCompletionsByLocation(selectedLocation.id)
      .then((data) => {
        console.log("coursecompletion", data);
        setIsCoursesLoading(false);
        setCourses(data);
      })
      .catch((error) => {
        console.error("Error fetching courses", error);
      });

    getUpcomingWebinarsByLocation(selectedLocation.id)
      .then((data) => {
        console.log("webinars", data);
        setIsWebinarsLoading(false);
        setWebinars(data);
      })
      .catch((error) => {
        console.error("Error fetching webinars", error);
      });
  }, [selectedLocation]);
  return (
    <div className="min-h-screen flex flex-col bg-white">
      {/* Header */}
      <HeaderComponent />

      {/* Profile Card */}
      <div className=" grid grid-cols-1 lg:grid-cols-3 gap-8 px-10 mb-8">
        <div className="bg-white shadow-lg rounded-3xl p-8 w-full max-w-md text-center mx-auto">
          <img
            src={`https://66-dementia.b-cdn.net/test/users/pictures/${admin.id}`}
            alt="Profile"
            className="w-24 h-24 mx-auto rounded-full shadow-md mb-4"
          />
          <h2 className="text-xl font-bold mb-2">{admin.name}</h2>
          <p className="text-gray-600 font-medium mb-6">{admin.roles[0]}</p>

          <select
            className="block w-full bg-gray-100 border border-gray-300 rounded-md py-2 px-3 mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            value={selectedLocation.id}
            onChange={(e) => {
              setSelectedLocation(
                locations.find((location) => {
                  return location.id === +e.target.value;
                })
              );
            }}
          >
            {/* <option>Choose Location</option> */}
            {locations.map((location) => (
              <option key={location.id} value={location.id}>
                {location.name}
              </option>
            ))}
          </select>

          <p className="text-gray-700 mb-1">
            Email:{" "}
            <a
              href="mailto:martin.johnston@6ddementia.org"
              className="text-blue-600"
            >
              {admin.email_address}
            </a>
          </p>
          <p className="text-gray-700">Telephone: {admin.phone_number}</p>
        </div>

        <div className="bg-white shadow-lg rounded-3xl p-8 col-span-2">
          <h3 className="text-2xl font-medium mb-10">
            Training Courses for {selectedLocation.name}
          </h3>
          {isCoursesLoading && (
            <div role="status" className="ml-[50%]">
              <svg
                aria-hidden="true"
                className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          )}
          {!isCoursesLoading &&
            courses &&
            courses.map((course, index) => (
              <div key={course.course_id} className="mb-10">
                <div className="flex justify-between items-center">
                  <span>{course.course_name}</span>
                  <span>{Math.round(course.percent_complete)}% Complete</span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2.5">
                  <div
                    className="bg-green-500 h-2.5 rounded-full"
                    style={{ width: `${course.percent_complete}%` }}
                  ></div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className="bg-gray-100 mb-4 p-6 flex justify-center rounded-xl items-center">
        <div className="w-full">
          <h1 className="text-2xl font-medium text-gray-800 mb-4">
            Upcoming Webinars for {selectedLocation.name}
          </h1>
          {isWebinarsLoading && (
            <div role="status" className="ml-[50%]">
              <svg
                aria-hidden="true"
                className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          )}

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-16">
            {!isWebinarsLoading &&
              webinars.length > 0 &&
              webinars.map((webinar, index) => (
                <div
                  key={webinar.id}
                  className="bg-white rounded-lg shadow-lg p-2 flex flex-col justify-between items-center text-center"
                >
                  <div>
                    <img
                      src={`https://66-dementia.b-cdn.net/test/webinars/images/${webinar.id}`}
                      alt={webinar.title}
                      className="w-42 h-32 object-cover rounded-lg mb-2"
                    />
                    <div className="text-center mb-2">
                      <h3
                        className="text-xl text-customBlue cursor-pointer"
                        onClick={() =>
                          navigate("/webinarregistrationlist", {
                            state: {
                              id: webinar.id,
                              title: webinar.title,
                              subtitle: webinar.description,
                              presentedBy: webinar.presenter,
                              date: new Date(
                                webinar.date_time
                              ).toLocaleDateString(),
                              time: webinar.date_time,
                            },
                          })
                        }
                      >
                        {webinar.title}
                      </h3>
                      {/* <p className="italic">{webinar.description}</p> */}
                    </div>
                    <div className="space-y-1">
                      <p>Presented By: {webinar.presenter}</p>
                      <p>
                        Time: {new Date(webinar.date_time).toLocaleDateString()}{" "}
                        - {new Date(webinar.date_time).getHours()}:
                        {new Date(webinar.date_time).getMinutes()}
                      </p>
                      <p>Staff Registered: {webinar.registered_users}</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {!isWebinarsLoading &&
              webinars &&
              webinars.map((webinar, index) => (
                <div
                  key={webinar.id}
                  className="bg-gray-50 p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
                >
                  <h2 className="text-lg font-semibold text-gray-800 mb-2">
                    {webinar.title}
                  </h2>
                  <p className="text-sm text-gray-600 mb-1">
                    Date: {webinar.date_time.split("T")[0]}
                  </p>
                 
                  <p className="text-sm text-gray-600">
                    Registered Users: {webinar.registered_users}
                  </p>
                </div>
              ))}
          </div> */}
        </div>
      </div>

      <FooterComponent path={-1} />
    </div>
  );
};

export default AdministratorProfile;
