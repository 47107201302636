import React, { useEffect, useState } from "react";
import Header from "./Header";
// import module1 from "../../images/coursedetails/1.png";
// import module2 from "../../images/coursedetails/2.png";
// import module3 from "../../images/coursedetails/3.png";
// import module4 from "../../images/coursedetails/4.png";
import { useLocation, useNavigate } from "react-router-dom";
import { getLessonsByModuleAndUser } from "../../apiFunction/ApiFunctions";

const Lessons = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [isLoading, setIsLoading] = useState(true);
  const [lessons, setLessons] = useState(null);
  const [focusedLesson, setFocusedLesson] = useState(null);
  const [focusedLessonNumber, setFocusedLessonNumber] = useState(null);
  const user = JSON.parse(sessionStorage.getItem("userdetails"));
  console.log(state);

  useEffect(() => {
    getLessonsByModuleAndUser(state.moduleId)
      .then((data) => {
        console.log("lessons", data);
        const sortedLessons = data.sort((a, b) => a.id - b.id);
        setIsLoading(false);
        setLessons(sortedLessons);
        setFocusedLesson(sortedLessons[0]);
        setFocusedLessonNumber(1);
      })
      .catch((error) => {
        console.error("Error fetching lessons", error);
      });
  }, [state.moduleId]);

  // const lessons = [
  //   {
  //     id: 1,
  //     title: "Dementia Types",
  //     completed: false,
  //     duration_minutes: 10,
  //     description:
  //       "Alzheimer's Disease.Vascular Dementia.Lewy Body Dementia.Frontotemporal Dementia.Mixed Dementia",
  //     imageUrl: module1,
  //     video_id: "a548d2e5-7cfc-462b-bf9e-a816f5c2529a",
  //   },
  //   {
  //     id: 2,
  //     title: "The Brain",
  //     description:
  //       "Alzheimer's Disease.Vascular Dementia.Lewy Body Dementia.Frontotemporal Dementia.Mixed Dementia",
  //     completed: false,
  //     imageUrl: module2,
  //     buttonText: "View",
  //     video_id: "a548d2e5-7cfc-462b-bf9e-a816f5c2529a",
  //   },
  //   {
  //     id: 3,
  //     title: "Bookcase Model of Memory",
  //     description:
  //       "Alzheimer's Disease.Vascular Dementia.Lewy Body Dementia.Frontotemporal Dementia.Mixed Dementia",
  //     completed: false,
  //     imageUrl: module3,
  //     buttonText: "View",
  //     video_id: "a548d2e5-7cfc-462b-bf9e-a816f5c2529a",
  //   },
  //   {
  //     id: 4,
  //     title: "Symptoms of Dementia",
  //     description:
  //       "Alzheimer's Disease.Vascular Dementia.Lewy Body Dementia.Frontotemporal Dementia.Mixed Dementia",
  //     completed: false,
  //     imageUrl: module4,
  //     buttonText: "View",
  //     video_id: "a548d2e5-7cfc-462b-bf9e-a816f5c2529a",
  //   },
  // ];

  return (
    <div className="min-h-screen bg-white py-8 flex flex-col items-center">
      <div className="container mx-auto px-4">
        <Header />
        <h2 className="text-2xl font-bold mb-2">
          Module {state.moduleNumber} : {state.moduleTitle}
        </h2>
        {isLoading && (
          <div role="status" className="ml-[50%]">
            <svg
              aria-hidden="true"
              className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        )}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div>
            {lessons &&
              lessons.length > 0 &&
              lessons
                // .filter((lesson) => lesson.id !== focusedLesson.id)
                .map((lesson, index) => (
                  <div
                    key={lesson.id}
                    className="bg-white p-4 rounded-lg shadow-lg mb-4"
                    onClick={() => {
                      setFocusedLesson(lesson);
                      setFocusedLessonNumber(index + 1);
                    }}
                  >
                    <div className="flex items-center">
                      <img
                        src={`https://66-dementia.b-cdn.net/test/lessons/images/${lesson.id}`}
                        alt={lesson.title}
                        className="w-32 object-cover rounded-lg mr-4"
                      />
                      <div>
                        <h3 className="text-xl font-bold mb-1">
                          {`Lesson ${index + 1} :`} {lesson.title}
                        </h3>
                        <p className="text-gray-700 mb-2">
                          Completed: {lesson.completed ? "Yes" : "No"}
                        </p>

                        {/* <button
                          className="bg-blue-900 text-white px-6 py-2 rounded-lg shadow-lg hover:bg-blue-700"
                          onClick={() => {
                            navigate("/mediaplayer", {
                              state: {
                                type: "course",
                                title: state.courseName,
                                moduleNumber: state.moduleNumber,
                                moduleTitle: state.moduleTitle,
                                lessonNumber: index + 2,
                                lessonId: lesson.id,
                                lessonTitle: lesson.title,
                                videoId: lesson.video_id,
                              },
                            });
                          }}
                        >
                          View
                        </button> */}
                      </div>
                    </div>
                  </div>
                ))}
          </div>
          <div>
            {lessons && lessons.length > 0 && (
              <div className="bg-white p-8 rounded-lg shadow-lg">
                <img
                  src={`https://66-dementia.b-cdn.net/test/lessons/images/${focusedLesson.id}`}
                  alt={focusedLesson.title}
                  className="w-full  object-cover rounded-lg mb-4"
                />
                <h3 className="text-xl font-bold mb-2">
                  Lesson {focusedLessonNumber} : {focusedLesson.title}
                </h3>
                <div className=" mb-4">
                  <div className="bg-white py-2  w-[40%] rounded-3xl shadow-lg flex items-center justify-evenly">
                    <img
                      src={`https://66-dementia.b-cdn.net/test/users/pictures/${user.id}`}
                      alt="Profile"
                      className=" h-10 w-10 rounded-2xl object-cover"
                    />
                    <p className=" text-gray-700">
                      {focusedLesson.completed ? "" : "Not"} Completed
                    </p>
                  </div>
                  <p className=" text-gray-700 mt-2 flex items-center">
                    <svg
                      className="w-5 h-5 text-blue-900"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
                    </svg>
                    <span className="ml-2">
                      {focusedLesson.duration_minutes} min
                    </span>
                  </p>
                </div>
                <ul className="list-disc list-inside text-gray-700 mb-4">
                  {focusedLesson.description.split(".").map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
                <div className="flex justify-center">
                  <button
                    className="bg-blue-900 text-white px-20 py-2 rounded-lg shadow-lg hover:bg-blue-700"
                    onClick={() => {
                      navigate("/mediaplayer", {
                        state: {
                          type: "course",
                          title: state.courseName,
                          moduleNumber: state.moduleNumber,
                          moduleTitle: state.moduleTitle,
                          lessonNumber: focusedLessonNumber,
                          lessonId: focusedLesson.id,
                          lessonTitle: focusedLesson.title,
                          videoId: focusedLesson.video_id,
                        },
                      });
                    }}
                  >
                    View
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="mt-8 flex justify-center">
          <button
            className="bg-blue-900 text-white px-10 py-2 rounded-3xl shadow-lg hover:bg-blue-700"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default Lessons;
