import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateUserComponent = () => {
  const auth = JSON.parse(sessionStorage.getItem("userdetails"));
  console.log("auth", auth.roles);
  return auth.roles[0] !== "Administrator" &&
    auth.roles[0] !== "OrganisationAdministrator" ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateUserComponent;
