import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import HeaderComponent from "./shared/HeaderComponent";
import FooterComponent from "./shared/FooterComponent";
import dementia from "../../images/dementia.png";
import behaviourChange from "../../images/user2.png";
import stressBehaviour from "../../images/user1.png";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteModule, getModules } from "../../apiFunction/ApiFunctions";
// import homeIcon from "../../images/home_icon.png";

const CourseDetailsPage = () => {
  //   const navigate = useNavigate();

  const [modules, setModules] = useState([]);
  const [moduleId, setModuleId] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const env = process.env.REACT_APP_ENV;
  const [focusedModule, setFocusedModule] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  // Function to open the modal
  function openModal(id) {
    console.log("modal open");
    setModuleId(id);
    setIsModalOpen(true);
  }

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  function confirmDelete(id) {
    deleteModule(id).then(() => {
      window.location.reload();
    });
    setIsModalOpen(false);
  }

  useEffect(() => {
    getModules(state.id).then((data) => {
      const sortedData = data.sort((a, b) => a.id - b.id);
      setModules(sortedData);
      console.log("data", data);
      setFocusedModule(sortedData[0]);
      setLoading(false);
    });
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />
      <main className="flex flex-col items-center">
        <div className="w-full max-w-8xl rounded-lg px-8 space-y-6 ml-4 mb-8">
          <div className="flex justify-between items-center">
            <h2 className="text-3xl text-black ml-[-1%]">{state.name}</h2>
            <button
              className="bg-yellow-600 text-white py-2 px-6 rounded-lg"
              onClick={() =>
                navigate("/create-module", {
                  state: {
                    course_id: state.id,
                    course_name: state.name,
                  },
                })
              }
            >
              Create Module
            </button>
          </div>
          {loading ? (
            // Show loading icon while fetching data
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
            </div>
          ) : (
            <>
              {modules.length > 0 ? (
                <div className="w-full flex justify-between items-start mt-6 space-x-6">
                  <div className="flex flex-col w-[45%] space-y-6 h-[10%]">
                    {/* <h2 className="text-3xl text-black">{state.name}</h2> */}
                    {modules.map((module, index) => (
                      <div
                        key={index}
                        className="bg-white rounded-lg shadow-lg flex p-4 items-center"
                        onClick={() => setFocusedModule(module)}
                      >
                        <img
                          src={`https://66-dementia.b-cdn.net/${env}/modules/images/${
                            module.id
                          }?${new Date().getTime()}`}
                          alt={module.title}
                          className="w-32 h-20 object-cover rounded-lg"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "https://via.placeholder.com/150"; // Use a placeholder URL
                          }}
                        />
                        <div className="ml-4">
                          <h3 className="font-semibold text-lg">
                            Module: {module.title}
                          </h3>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="w-2/3 bg-white rounded-lg shadow-lg p-6">
                    <img
                      src={`https://66-dementia.b-cdn.net/${env}/modules/images/${
                        focusedModule.id
                      }?${new Date().getTime()}`}
                      alt="Dementia"
                      className="w-full h-64 object-cover rounded-lg"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "https://via.placeholder.com/150"; // Use a placeholder URL
                      }}
                    />
                    <div className="mt-4">
                      <h2 className="text-2xl font-semibold">
                        Module: {focusedModule.title}
                      </h2>
                      <div className="flex items-center mt-2">
                        <span className="text-gray-600">
                          {focusedModule.duration}
                        </span>
                        <span className="ml-4 text-gray-600">
                          {focusedModule.average_rating}/5.0
                        </span>
                      </div>
                      <ul className="mt-4 list-disc list-inside text-gray-700">
                        <p>{focusedModule.description}</p>
                      </ul>

                      <center>
                        <button
                          className="bg-blue-600 text-white py-2 px-4 rounded-lg mt-4 w-32"
                          onClick={() => {
                            navigate("/lesson-list", {
                              state: {
                                id: focusedModule.id,
                                title: focusedModule.title,
                                course_id: state.id,
                                course_name: state.name,
                              },
                            });
                          }}
                        >
                          View
                        </button>

                        <button
                          className="bg-yellow-600 text-white py-2 px-4 rounded-lg mt-4 ml-4 w-32"
                          onClick={() =>
                            navigate("/create-module", {
                              state: {
                                id: focusedModule.id,
                                course_id: state.id,
                                course_name: state.name,
                                title: focusedModule.title,
                                description: focusedModule.description,
                                image: `https://66-dementia.b-cdn.net/${env}/modules/images/${
                                  focusedModule.id
                                }?${new Date().getTime()}`,
                              },
                            })
                          }
                        >
                          Edit
                        </button>

                        <button
                          className="bg-red-600 text-white py-2 px-4 rounded-lg mt-4 ml-4 w-32"
                          onClick={() => openModal(focusedModule.id)}
                        >
                          Delete
                        </button>
                      </center>
                    </div>
                  </div>
                </div>
              ) : (
                <h1 className="flex justify-center text-xl">There is no Modules. Please create one.</h1>
              )}
            </>
          )}
          {/* Modal */}
          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
                <h2 className="text-xl font-bold mb-4">Confirm Delete</h2>
                <p className="text-gray-600 mb-6">
                  Are you sure you want to delete this item?
                </p>
                <div className="flex justify-end space-x-4">
                  <button
                    className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-700"
                    onClick={() => confirmDelete(moduleId)}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </main>
      <FooterComponent path={"/training-courses"} />
    </div>
  );
};

export default CourseDetailsPage;
