import HeaderComponent from "./shared/HeaderComponent";
import dementia from "../../images/strees&behaviour.png";
import behaviouralChange from "../../images/changes_in_behaviour.png";
import stressAndBehaviour from "../../images/dementia.png";
import play from "../../images/play_button.jpeg";
import React, { useEffect } from "react";
import FooterComponent from "./shared/FooterComponent";
import { useNavigate } from "react-router-dom";
import VideoModal from "./shared/VideoModalComponent";
import { useState } from "react";
import localVideo from "../../videos/sample.mp4";
import {
  getAllPdfDocumentsByOrganisation,
  getAllResearchPapersByOrganisation,
  getAllSixdHandbooksByOrganisation,
} from "../../apiFunction/ApiFunctions";
// import localVideo from "../../videos/.mp4"; // Update the path to your local video

const SupportingMaterialsPage = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [researchPapers, setResearchPapers] = useState(null);
  const [handbooks, setHandbooks] = useState(null);
  const [pdfDocuments, setPdfDocuments] = useState(null);
  const [isResearchPapersLoading, setIsResearchPapersLoading] = useState(true);
  const [isHandbooksLoading, setIsHandbooksLoading] = useState(true);
  const [isPdfLoading, setIsPdfLoading] = useState(true);
  const user = JSON.parse(sessionStorage.getItem("userdetails"));

  useEffect(() => {
    getAllResearchPapersByOrganisation()
      .then((data) => {
        console.log("research papers", data);
        setIsResearchPapersLoading(false);
        setResearchPapers(data);
      })
      .catch((error) => {
        console.error("Error fetching papers", error);
      });

    getAllSixdHandbooksByOrganisation()
      .then((data) => {
        console.log("hand books", data);
        setIsHandbooksLoading(false);
        setHandbooks(data);
      })
      .catch((error) => {
        console.error("Error fetching hand books", error);
      });

    getAllPdfDocumentsByOrganisation()
      .then((data) => {
        console.log("pdf documents", data);
        setIsPdfLoading(false);
        setPdfDocuments(data);
      })
      .catch((error) => {
        console.error("Error fetching pdf documents", error);
      });
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleResearchPaper = async (paper) => {
    console.log(paper);
    const url = `https://66-dementia.b-cdn.net/test/researchPapers/contents/${paper.id}.pdf`;
    console.log("url", url);
    window.open(url);
    // window.open("https://dih-test.b-cdn.net/6D%20Dementia%20Training/Please%20C%20Me.pdf");

    // const response = await fetch(url);
    // console.log(response);
  };

  const handle6DHandbook = async (handBook) => {
    console.log(handBook);
    const url = `https://66-dementia.b-cdn.net/test/sixdHandbooks/contents/${handBook.id}.pdf`;
    console.log("url", url);
    window.open(url);
    // window.open("https://dih-test.b-cdn.net/6D%20Dementia%20Training/Please%20C%20Me.pdf");

    // const response = await fetch(url);
    // console.log(response);
  };

  const handlePdfDownload = (pdf) => {
    // const link = document.createElement("a");
    // link.href =
    //   "https://dih-test.b-cdn.net/6D%20Dementia%20Training/Please%20C%20Me.pdf";

    // link.download = "file.pdf"; // This will be the file name
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);

    console.log(pdf);
    const url = `https://66-dementia.b-cdn.net/test/pdfDocuments/contents/${pdf.id}.pdf`;
    console.log("url", url);
    window.open(url);
  };

  // const favouriteModules = [
  //   {
  //     title: "What is Dementia",
  //     image: dementia,
  //     button: "View",
  //   },
  //   {
  //     title: "Changes in Behaviour",
  //     image: behaviouralChange,
  //     button: "View",
  //     videoUrl:
  //       "https://www.youtube.com/watch?v=ajGo94h0JxE&list=RDajGo94h0JxE",
  //   },
  //   {
  //     title: "Stress and Behaviour",
  //     image: stressAndBehaviour,
  //     button: "View",
  //     videoUrl:
  //       "https://www.youtube.com/watch?v=ajGo94h0JxE&list=RDajGo94h0JxE",
  //   },
  // ];

  // const researchPapers = [
  //   {
  //     title: "Dementia Stress and Behaviour",
  //     author: "Fred Bloggs",
  //     date: "15/02/2023",
  //     button: "Download",
  //   },
  //   {
  //     title: "Psychological well-being in Dementia",
  //     author: "John Jones",
  //     date: "03/04/1999",
  //     button: "Download",
  //   },
  // ];

  // const handbooks = [
  //   "Company Guidelines",
  //   "Risk Management",
  //   "Company Handbook",
  // ];

  const assessments = [
    { title: "Care Staff Training Assessment 1", button: "Start Assessment" },
    {
      title: "Dementia Team Training Assessment 2",
      button: "Start Assessment",
    },
  ];

  // const pdfDocuments = [
  //   { title: "Training Introduction", button: "Download" },
  //   { title: "Training Objectives", button: "Download" },
  // ];

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />
      <main className="w-full p-6 flex flex-col items-center mt-5">
        <div className="w-full max-w-8xl rounded-lg px-8 space-y-6 ml-4">
          {user.roles[0] === "OrganisationAdministrator" && (
            <div className="flex justify-end">
              <button
                className="bg-yellow-500 text-white py-2 px-8 rounded-lg"
                onClick={() => navigate("/upload-supporting-materials")}
              >
                Upload Supporting Material
              </button>
            </div>
          )}
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-6">
            {/* Modules */}

            {/* <div>
              <h2 className="text-2xl font-semibold text-customBlue mb-4">
                Favourite Modules
              </h2>
              <div className="bg-white rounded-lg shadow-lg p-4 mb-4">
                {favouriteModules.map((module, index) => (
                  <div key={index} className="flex">
                    <img
                      src={module.image}
                      alt={module.title}
                      className="h-24 w-32 object-cover rounded-lg mb-4"
                    />
                    <div className="ml-4 mt-2">
                      <h3 className="font-semibold mb-2">{module.title}</h3>
                      <div className="flex justify-center mt-2">
                        <img
                          src={play}
                          alt={module.title}
                          className="w-8 h-8 cursor-pointer"
                          onClick={() => navigate("/mediaplayer")}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <button className="bg-customBlue text-white py-2 px-4 rounded-lg w-full mt-5">
                  View
                </button>
              </div>
            </div> */}

            <div>
              <h2 className="text-2xl font-semibold text-customBlue mb-4">
                Research Papers
              </h2>
              {isResearchPapersLoading && (
                <div role="status" className="ml-[50%]">
                  <svg
                    aria-hidden="true"
                    className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              {researchPapers &&
                researchPapers.map((paper, index) => (
                  <div
                    key={paper.id}
                    className="bg-white rounded-lg shadow-lg p-4 mb-4"
                  >
                    <h3 className="flex justify-center text-xl font-semibold mb-2">
                      {paper.title}
                    </h3>
                    <p className="text-gray-700">Author: {paper.authors}</p>
                    <p className="text-gray-700 mb-4">
                      Publication Date: {paper.publication_date}
                    </p>
                    <button
                      className="bg-customBlue text-white py-2 px-4 rounded-lg w-full"
                      onClick={() => handleResearchPaper(paper)}
                    >
                      Open
                    </button>
                  </div>
                ))}
            </div>
            <div>
              <h2 className="text-2xl font-semibold text-customBlue mb-4">
                6D Handbooks
              </h2>
              <div className="bg-white rounded-lg shadow-lg p-4 mb-4">
                {isHandbooksLoading && (
                  <div role="status" className="ml-[50%]">
                    <svg
                      aria-hidden="true"
                      className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
                {handbooks &&
                  handbooks.map((handbook, index) => (
                    <button
                      key={handbook.id}
                      className="bg-gray-300 text-black py-4 px-4 rounded-lg w-full mt-4"
                      onClick={() => handle6DHandbook(handbook)}
                    >
                      {handbook.title}
                    </button>
                  ))}
              </div>
            </div>
            <div>
              <h2 className="text-2xl font-semibold text-customBlue mb-4">
                Assessments
              </h2>
              {assessments.map((assessment, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg shadow-lg p-4 mb-4"
                >
                  <p className="mb-2">{assessment.title}</p>
                  <button className="bg-customBlue text-white py-2 px-4 rounded-lg w-full">
                    {assessment.button}
                  </button>
                </div>
              ))}
            </div>
            <div>
              <h2 className="text-2xl font-semibold text-customBlue mb-4">
                PDF Documents
              </h2>
              {isPdfLoading && (
                <div role="status" className="ml-[50%]">
                  <svg
                    aria-hidden="true"
                    className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              {pdfDocuments &&
                pdfDocuments.map((pdf, index) => (
                  <div
                    key={pdf.id}
                    className="bg-white rounded-lg shadow-lg p-4 mb-4"
                  >
                    <h4 className="flex justify-center text-xl font-semibold mb-2">
                      {pdf.title}
                    </h4>
                    <button
                      className="bg-customBlue text-white py-2 px-4 rounded-lg w-full"
                      onClick={() => handlePdfDownload(pdf)}
                    >
                      Download
                    </button>
                  </div>
                ))}
            </div>
          </div>
          <FooterComponent path={-1} />
        </div>
      </main>
      <VideoModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        videoUrl={localVideo}
      />
    </div>
  );
};

export default SupportingMaterialsPage;
