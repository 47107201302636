import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getCourseAverageScoresByOrganisation } from "../../apiFunction/ApiFunctions";
import FooterComponent from "../admin/shared/FooterComponent";
import Header from "../user/Header";

const ManagerDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [scores, setScores] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // const courses = [
  //   {
  //     name: "6D Training for Teams",
  //     progress: 80,
  //   },
  //   {
  //     name: "6D Specialist Team Training",
  //     progress: 50,
  //   },
  //   {
  //     name: "6D Advanced Team Training",
  //     progress: 30,
  //   },
  // ];

  // const scores = [
  //   {
  //     course_name: " Specialist Team Training",
  //     average_percent: 95,
  //   },
  //   {
  //     course_name: " Advanced Team Training",
  //     average_percent: 98,
  //   },
  //   {
  //     course_name: "Team Training",
  //     average_percent: 99,
  //   },
  // ];

  useEffect(() => {
    getCourseAverageScoresByOrganisation()
      .then((data) => {
        setIsLoading(false);
        console.log("scores", data); // Access the responseData returned from the function
        setScores(data);
      })
      .catch((error) => {
        console.error("Error fetching webinars:", error);
      });
  }, []);

  return (
    <div className="min-h-screen  bg-white p-4 md:p-8">
      <Header />
      <div className="md:flex">
        <div className="md:w-1/2">
          <div className="flex flex-col items-center justify-center mb-16">
            <div className="w-full max-w-lg space-y-20">
              {state.courses.map((course, index) => (
                <div
                  key={index}
                  className="bg-white rounded-3xl shadow-lg p-6 justify-between"
                  onClick={() => {
                    navigate("/teamprogress", {
                      state: {
                        courseName: course.course_name,
                        overallProgress: course.percent_complete,
                      },
                    });
                  }}
                >
                  <h2 className="text-lg text-center font-semibold">
                    {course.course_name}
                  </h2>
                  <div className="mt-4 h-4 bg-gray-300 rounded-full overflow-hidden">
                    <div
                      className="h-full bg-[#0089ad] rounded-full"
                      style={{ width: `${course.percent_complete}%` }}
                    ></div>
                  </div>
                  <p className="mt-2 text-sm text-gray-500">
                    {course.percent_complete}% Complete
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="md:w-1/2 ">
          <div className="flex flex-col items-center justify-center mb-16">
            <h1 className="text-xl font-bold text-gray-800 mb-6">
              Training Courses
            </h1>

            <div className=" w-full max-w-lg space-y-16">
              {isLoading && (
                <div role="status" className="ml-[50%]">
                  <svg
                    aria-hidden="true"
                    className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              {scores &&
                scores.map((score, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between bg-white p-4 rounded-3xl shadow-lg"
                  >
                    <div className="flex items-center w-[25%]">
                      <div className="bg-[#f2cddd] p-3 rounded-lg ">
                        <p className="text-black text-center ">
                          {score.course_name}
                        </p>
                      </div>
                    </div>
                    <div className="w-[70%]">
                      <div className="flex items-center justify-between">
                        <p className="text-gray-800 font-medium">
                          Average {score.course_name} Evaluation
                        </p>
                        <p className="text-2xl text-gray-900">
                          {score.average_percent ? score.average_percent : 0}%
                        </p>
                      </div>
                      <div className="w-full bg-gray-300 rounded-full h-2.5 mt-1">
                        <div
                          className="bg-gray-600 h-2.5 rounded-full"
                          style={{
                            width: `${
                              score.average_percent ? score.average_percent : 0
                            }%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                ))}

              {/* Specialist Team Training */}
              {/* <div className="flex items-center justify-between bg-white p-4 rounded-3xl shadow-lg">
                <div className="flex items-center w-[25%]">
                  <div className="bg-[#f2cddd] p-3 rounded-lg ">
                    <p className="text-black text-center ">
                      Specialist Team Training
                    </p>
                  </div>
                </div>
                <div className="w-[70%]">
                  <div className="flex items-center justify-between">
                    <p className="text-gray-800 font-medium">
                      Average Specialist Evaluation
                    </p>
                    <p className="text-2xl text-gray-900">95%</p>
                  </div>
                  <div className="w-full bg-gray-300 rounded-full h-2.5 mt-1">
                    <div
                      className="bg-gray-600 h-2.5 rounded-full"
                      style={{ width: "95%" }}
                    ></div>
                  </div>
                </div>
              </div> */}

              {/* Advanced Team Training */}
              {/* <div className="flex items-center justify-between bg-white p-4 rounded-3xl shadow-lg">
                <div className="flex items-center w-[25%]">
                  <div className="bg-[#f2cddd] p-3 rounded-lg ">
                    <p className="text-black text-center ">
                      Advanced Team Training
                    </p>
                  </div>
                </div>
                <div className="w-[70%]">
                  <div className="flex items-center justify-between">
                    <p className="text-gray-800 font-medium">
                      Average Advanced Evaluation
                    </p>
                    <p className="text-2xl text-gray-900">98%</p>
                  </div>
                  <div className="w-full bg-gray-300 rounded-full h-2.5 mt-1">
                    <div
                      className="bg-gray-600 h-2.5 rounded-full"
                      style={{ width: "98%" }}
                    ></div>
                  </div>
                </div>
              </div> */}

              {/* Team Training */}
              {/* <div className="flex items-center justify-between bg-white p-4 rounded-3xl shadow-lg">
                <div className="flex items-center w-[25%]">
                  <div className="bg-[#f2cddd] p-3 rounded-lg ">
                    <p className="text-black text-center ">Team Training</p>
                  </div>
                </div>
                <div className="w-[70%]">
                  <div className="flex items-center justify-between">
                    <p className="text-gray-800 font-medium">
                      Average Team Training Evaluation
                    </p>
                    <p className="text-2xl text-gray-900">99%</p>
                  </div>
                  <div className="w-full bg-gray-300 rounded-full h-2.5 mt-1">
                    <div
                      className="bg-gray-600 h-2.5 rounded-full"
                      style={{ width: "99%" }}
                    ></div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <FooterComponent path={-1} />
    </div>
  );
};

export default ManagerDashboard;
