import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getLocationsByOrganisation } from "../../apiFunction/ApiFunctions";
import FooterComponent from "../admin/shared/FooterComponent";
import HeaderComponent from "../admin/shared/HeaderComponent";

const Teams = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  console.log("state", state);
  const [teams, setTeams] = useState(state ? state.locations : null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (teams === null) {
      setIsLoading(true);
      getLocationsByOrganisation()
        .then((data) => {
          console.log("locations", data);
          setIsLoading(false);
          setTeams(data);
        })
        .catch((error) => {
          console.error("Error fetching total users:", error);
        });
    }
  }, [teams]);
  // const teams = [
  //   {
  //     name: "Lisburn Care Home",
  //     image_url: require("../../images/teams/image.png"),
  //   },
  //   {
  //     name: "Lisburn Road Care Home",
  //     image_url: require("../../images/teams/image (1).png"),
  //   },
  //   {
  //     name: "Antrim Care Home",
  //     image_url: require("../../images/teams/image (2).png"),
  //   },
  // ];

  const otherTeams = [
    {
      name: "Headquarters",
      image_url: require("../../images/teams/image (3).png"),
    },
    {
      name: "Craigavon Care Home",
      image_url: require("../../images/teams/image (4).png"),
    },
  ];

  return (
    <div className="min-h-screen bg-white p-6">
      <HeaderComponent />
      <div className="w-[90%] mx-auto  mb-8">
        <div className=" bg-white rounded-lg shadow-lg p-8 ">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-semibold">Locations</h2>
            <button
              className="bg-[#ff9500] text-black text-xl px-8 py-2 rounded-full hover:bg-yellow-500 transition"
              onClick={() =>
                navigate("/createteam", {
                  state: {
                    locations: teams,
                  },
                })
              }
            >
              Create
            </button>
          </div>
          {isLoading && (
            <div role="status" className="ml-[50%]">
              <svg
                aria-hidden="true"
                className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          )}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {teams &&
              teams.map((team, index) => (
                <div
                  key={index}
                  className="bg-white p-4 rounded-3xl shadow-lg mb-6 overflow-hidden flex items-center"
                >
                  <img
                    src={`https://66-dementia.b-cdn.net/test/locations/images/${team.id}`}
                    alt={team.name}
                    className="w-[172px] object-cover"
                  />
                  <div className="px-4">
                    <h3 className="text-lg font-semibold mb-8 ">{team.name}</h3>
                    <button
                      className="bg-[#0089ad] text-white px-4 py-2  hover:bg-blue-700 transition"
                      onClick={() =>
                        navigate("/userlist", {
                          state: {
                            selectedLocation: team,
                            locations: teams,
                          },
                        })
                      }
                    >
                      Choose
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
        {/* <div className="rounded-lg shadow-lg">
          {otherTeams.map((team, index) => (
            <div key={index} className="bg-white mb-6 overflow-hidden ">
              <img
                src={team.image_url}
                alt={team.name}
                className="w-1/2 mx-auto  object-cover"
              />
              <div className="p-4 text-center">
                <h3 className="text-lg font-semibold mb-2">{team.name}</h3>
                <button className="bg-[#0089ad] text-white px-4 py-2  hover:bg-blue-700 transition">
                  Choose
                </button>
              </div>
            </div>
          ))}
        </div> */}
      </div>
      <FooterComponent path={-1} />
    </div>
  );
};

export default Teams;
