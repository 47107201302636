import React from "react";
import training from "../../images/completedtraining/1.png";
import specialisttraining from "../../images/activetraining/3.png";
import advancedtraining from "../../images/activetraining/1.png";
import Header from "./Header";
import { useLocation, useNavigate } from "react-router-dom";

// const courses = [
//   {
//     title: "6D Training for Teams",
//     date: "1st January 2023",
//     score: "95%",
//     modules: 6,
//     image: training,
//   },
//   {
//     title: "6D Specialist Team Training",
//     date: "15th February 2023",
//     score: "88%",
//     modules: 7,
//     image: specialisttraining,
//   },
//   {
//     title: "6D Advanced Team Training",
//     date: "10th March 2023",
//     score: "98%",
//     modules: 6,
//     image: advancedtraining,
//   },
// ];

// const awards = [
//   {
//     title: "6D Training for Teams",
//     date: "7/5/23",
//   },
//   {
//     title: "6D Specialist Team Training",
//     date: "11/05/24",
//   },
// ];

const CourseCard = ({ course }) => (
  <div className="bg-white p-4 rounded-lg shadow-lg text-center">
    <img
      src={`https://66-dementia.b-cdn.net/test/courses/images/${course.course_id}`}
      alt={course.course_name}
      className="w-full h-48 object-cover rounded-lg mb-4"
    />
    <h3 className="text-xl font-bold mb-1">{course.course_name}</h3>
    <p className="text-gray-600 mb-1">{course.completion_date}</p>
    <p className="text-gray-600 mb-1">Score: {course.score}</p>
    <p className="text-gray-600 mb-1">Modules: {course.total_modules}</p>
  </div>
);

const AwardCard = ({ award }) => (
  <div className="bg-white p-4 rounded-lg shadow-lg flex flex-col items-center">
    <div className="text-center mb-4 flex flex-col items-center">
      <img
        src={require("../../images/coursedetails/certificate.png")}
        alt={award.course_name}
        className="h-32 object-cover rounded-lg mb-4"
      />
      <h4 className="text-xl text-[#0089ad] font-bold">{award.course_name}</h4>
      <p className="text-gray-600">{award.award_date}</p>
    </div>
  </div>
);

const CompletedCourses = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  console.log(state.courses.filter((award) => award.award_date !== null));
  return (
    <div className="min-h-screen bg-white py-8">
      <div className="container mx-auto px-4">
        <Header />

        {/* <div className="flex justify-between items-center mb-6 w-[70%]">
          <h5 className="text-xl font-semibold text-gray-700 w-1/5">
            Filter Options
          </h5>

          <input
            type="text"
            placeholder="Search by User's Name"
            className="border border-gray-300 rounded-lg p-2 w-1/5"
          />
          <input
            type="text"
            placeholder="Filter by Course Name"
            className="border border-gray-300 rounded-lg p-2 w-1/5"
          />
          <input
            type="text"
            placeholder="dd/mm/yy"
            className="border border-gray-300 rounded-lg p-2 w-1/5"
          />
        </div> */}

        <h2 className="text-2xl font-semibold text-gray-700 mb-2">
          Completed Courses
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
          {state.courses
            .filter((course) => course.percent_complete === 100)
            .map((course, index) => (
              <CourseCard key={index} course={course} />
            ))}
        </div>
        <h2 className="text-2xl font-semibold text-gray-700 mb-2">
          Awards & Certifications
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {state.courses
            .filter((award) => award.award_date !== null)
            .map((award, index) => (
              <AwardCard key={index} award={award} />
            ))}
        </div>
        <div className="mt-8 flex justify-center">
          <button
            className="bg-blue-900 text-white px-10 py-2 rounded-3xl shadow-lg hover:bg-blue-700"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompletedCourses;
