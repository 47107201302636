// src/components/CustomerListPage.js

import React, { useEffect, useState } from "react";
import HeaderComponent from "./shared/HeaderComponent";
import {
  deleteLesson,
  deleteWebinar,
  getLessonsByModule,
  getWebinars,
} from "../../apiFunction/ApiFunctions";
import { useNavigate, useLocation } from "react-router-dom";
import FooterComponent from "./shared/FooterComponent";

const LessonsPage = () => {
  const env = process.env.REACT_APP_ENV;

  const location = useLocation();
  const { state } = location;
  const [lessons, setLessons] = useState([]);
  const [lessonId, setLessonId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  function dateFormat(date_time) {
    // Create a new Date object
    const dateObj = new Date(date_time);

    // Format the date to "26 August, 2024"
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = dateObj.toLocaleDateString("en-US", options);

    // Extract the time and format to AM/PM
    let hours = dateObj.getUTCHours();
    const minutes = dateObj.getUTCMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // Adjust to 12-hour format (handle midnight as 12)
    const formattedTime = `${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm}`;

    // console.log("Date:", formattedDate); // Output: 26 August, 2024
    // console.log("Time:", formattedTime); // Output: 10:00 PM

    return formattedDate + " " + formattedTime;
  }

  // Function to open the modal
  function openModal(id) {
    console.log("modal open");
    setLessonId(id);
    setIsModalOpen(true);
  }

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  function confirmDelete(id) {
    deleteLesson(id).then(() => {
      // window.location.reload();
      setLessons(prevLessons => prevLessons.filter(lesson => lesson.id !== id));
    });
    setIsModalOpen(false);
  }

  useEffect(() => {
    getLessonsByModule(state.id)
      .then((data) => {
        const sortedData = data.sort((a, b) => a.id - b.id);
        console.log(sortedData); // Access the responseData returned from the function
        setLessons(sortedData);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching webinars:", error);
      });
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />

      <main className="w-full p-6 flex flex-col items-center">
        <div className="w-full max-w-8xl rounded-lg px-8 space-y-6 ml-4">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl text-customBlue ml-[-1%]">
              Module: {state.title}
            </h2>
            <button
              className="bg-yellow-500 text-white py-2 px-6 rounded-lg"
              onClick={() =>
                navigate("/create-lesson", {
                  state: {
                    module_id: state.id,
                    module_title: state.title,
                    course_id: state.course_id,
                    course_name: state.course_name,
                  },
                })
              }
            >
              Create Lesson
            </button>
          </div>
          {loading ? (
            // Show loading icon while fetching data
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
            </div>
          ) : (
            <>
              {lessons.length === 0 && (
                <div className="flex justify-center items-center mt-8">
                  <h2 className="text-xl">
                    No lessons have been associated with this module. Would you
                    like to create one?
                  </h2>
                </div>
              )}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-16">
                {lessons.length > 0 &&
                  lessons.map((lesson, index) => (
                    <div
                      key={index}
                      className="bg-white rounded-lg shadow-lg p-4 flex flex-col justify-between"
                    >
                      <div>
                        <img
                          // src={`https://66-dementia.b-cdn.net/${env}/webinars/images/${webinar.id}`}
                          src={`https://66-dementia.b-cdn.net/${env}/lessons/images/${
                            lesson.id
                          }?${new Date().getTime()}`}
                          alt={lesson.title}
                          className="w-full h-48 object-cover rounded-lg mb-4"
                          onError={(e) => {
                            e.target.onerror = null;
                            // e.target.src = "https://via.placeholder.com/150x150.png?text=Image+Not+Found"; // Use a placeholder URL
                            e.target.src = `https://dummyimage.com/300x300/cccccc/000000.png&text=Image+Not+Found`;
                          }}
                        />
                        <div className="mb-6">
                          <h3 className="text-xl text-customBlue mb-2 cursor-pointer">
                            {lesson.title}
                          </h3>
                          <p className="italic">{lesson.description}</p>
                        </div>
                        {/* <div className="space-y-2">
                        <p>
                          <b>Presented By:</b> {webinar.presenter}
                        </p>
                        <p>
                          <b>Time:</b> {dateFormat(webinar.date_time)}
                        </p>
                        <p>
                          <b>Duration (in minutes):</b>{" "}
                          {webinar.duration_minutes}
                        </p>
                        <p>
                          Staff Registered:{" "}
                          {webinar.staffRegistered
                            ? webinar.staffRegistered
                            : 0}
                        </p>
                      </div> */}
                        <div className="w-full mt-4 flex justify-around">
                          <button
                            className=" bg-blue-600 text-white py-2 px-6 rounded-3xl"
                            onClick={() => {
                              navigate("/mediaplayer", {
                                state: {
                                  type: "course",
                                  title: lesson.title,
                                  // moduleNumber: index,
                                  moduleTitle: state.title,
                                  // lessonNumber: 1,
                                  lessonId: lesson.id,
                                  lessonTitle: lesson.title,
                                  videoId: lesson.video_id,
                                },
                              });
                            }}
                          >
                            View
                          </button>
                          <button
                            className=" bg-yellow-500 text-white py-2 px-6 rounded-3xl"
                            onClick={() => {
                              navigate("/create-lesson", {
                                state: {
                                  id: lesson.id,
                                  module_id: lesson.module_id,
                                  module_title: state.title,
                                  title: lesson.title,
                                  description: lesson.description,
                                  author: lesson.author,
                                  about_author: lesson.about_author,
                                  duration_minutes: lesson.duration_minutes,
                                  key_topics: lesson.key_topics.join(", "),
                                  video_id: lesson.video_id,
                                  image: `https://66-dementia.b-cdn.net/${env}/lessons/images/${
                                    lesson.id
                                  }?${new Date().getTime()}`,
                                },
                              });
                            }}
                          >
                            Update
                          </button>
                          <button
                            className=" bg-red-600 text-white py-2 px-6 rounded-3xl"
                            onClick={() => openModal(lesson.id)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}
          {/* Modal */}
          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
                <h2 className="text-xl font-bold mb-4">Confirm Delete</h2>
                <p className="text-gray-600 mb-6">
                  Are you sure you want to delete this item?
                </p>
                <div className="flex justify-end space-x-4">
                  <button
                    className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-700"
                    onClick={() => confirmDelete(lessonId)}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </main>

      <FooterComponent
        path={"/course-details"}
        state={{ id: state.course_id, name: state.course_name }}
      />
    </div>
  );
};

export default LessonsPage;
