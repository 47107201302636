// import React, { useState } from "react";
// import HeaderComponent from "./shared/HeaderComponent";
// import { useNavigate, useLocation } from "react-router-dom";

// const CreateManager = () => {
//   const [name, setName] = useState("");
//   const [role, setRole] = useState("");
//   const [department, setDepartment] = useState("");
//   const [phone, setPhone] = useState("");
//   const [username, setUsername] = useState("");
//   const [password, setPassword] = useState("");
//   const [image, setImage] = useState(null);
//   const navigate = useNavigate();
//   const location = useLocation();
//   const { state } = location;
//   const [isDisabled, setIsDisabled] = useState(true);

//   const handleImageUpload = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setImage(reader.result);
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     // Prepare the form data
//     const formData = {
//       name,
//       role,
//       department,
//       phone,
//       email: username,
//       password,
//       image,
//     };

//     // Check if any previous data is already there or not
//     const sessionNewUser = JSON.parse(sessionStorage.getItem("newUser"));

//     let updatedFormData;
//     if (
//       sessionNewUser !== null &&
//       !Array.isArray(sessionNewUser) &&
//       typeof sessionNewUser === "object"
//     ) {
//       updatedFormData = [sessionNewUser, formData];
//     } else if (Array.isArray(sessionNewUser)) {
//       updatedFormData = [...sessionNewUser, formData];
//       console.log(updatedFormData);
//     } else {
//       updatedFormData = [formData];
//     }

//     // Save the form data to session storage
//     sessionStorage.setItem("newUser", JSON.stringify(updatedFormData));

//     // Log the form data
//     console.log("Form Data as JSON:", JSON.stringify(updatedFormData, null, 2));

//     // Navigate to homescreen
//     navigate("/user-list", { state });
//     // Handle form submission
//     // console.log({ name, role, department, phone, username, password, image });
//   };

//   const handleClick = () => {
//     console.log("This action is not allowed at this time.");
//     if (isDisabled) {
//       alert("This action is not allowed at this time.");
//       navigate("/user-list", { state });
//     }
//   };

//   return (
//     // <div className="min-h-screen bg-gray-100 flex flex-col">
//     <div className="min-h-screen bg-gray-100 flex flex-col">
//       <HeaderComponent />
//       <div className="container mx-auto">
//         <h1 className="text-3xl font-medium text-customBlue mb-8 ml-[-2%]">
//           Create User
//         </h1>
//         <form
//           className="bg-white shadow-md rounded-lg px-8 flex mb-8"
//           // onSubmit={handleSubmit}
//         >
//           <div className="w-5/6 p-6">
//             <div className="mb-4">
//               <label className="block text-gray-700">Name:</label>
//               <input
//                 type="text"
//                 value={name}
//                 onChange={(e) => setName(e.target.value)}
//                 className="w-full border p-2 rounded mt-1"
//                 required
//               />
//             </div>
//             <div className="mb-4 w-1/3">
//               <label className="block text-gray-700">Role:</label>
//               <select
//                 value={role}
//                 onChange={(e) => setRole(e.target.value)}
//                 className="w-full border p-2 rounded mt-1"
//               >
//                 <option value="">Choose the Manager's Role</option>
//                 <option value="Administrator">Administrator</option>
//                 <option value="Manager">Manager</option>
//               </select>
//             </div>
//             <div className="mb-4 w-1/3">
//               <label className="block text-gray-700">Department:</label>
//               <select
//                 value={department}
//                 onChange={(e) => setDepartment(e.target.value)}
//                 className="w-full border p-2 rounded mt-1"
//               >
//                 <option value="">Department</option>
//                 <option value="HR">Care Home 1</option>
//                 <option value="Finance">Care Home 2</option>
//                 <option value="Operations">Care Home 3</option>
//               </select>
//             </div>
//             <div className="mb-4">
//               <label className="block text-gray-700">Phone Number:</label>
//               <input
//                 type="text"
//                 value={phone}
//                 onChange={(e) => setPhone(e.target.value)}
//                 className="w-full border p-2 rounded mt-1"
//               />
//             </div>
//             <div className="mb-4">
//               <label className="block text-gray-700">Username:</label>
//               <input
//                 type="text"
//                 value={username}
//                 onChange={(e) => setUsername(e.target.value)}
//                 className="w-full border p-2 rounded mt-1"
//               />
//             </div>
//             <div className="mb-4">
//               <label className="block text-gray-700">Password:</label>
//               <input
//                 type="password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//                 className="w-full border p-2 rounded mt-1"
//               />
//             </div>
//             <div className="flex justify-center">
//               <button
//                 type="submit"
//                 className={`bg-blue-950 text-white text-xl py-2 px-8 rounded-lg mt-12 ${
//                   isDisabled ? "cursor-not-allowed opacity-50" : ""
//                 }`}
//                 onClick={
//                   !isDisabled
//                     ? () => {
//                         /* Actual submit logic here */
//                       }
//                     : handleClick
//                 }
//               >
//                 Create
//               </button>
//               {/* {isDisabled && (
//                 <div
//                   className="absolute inset-0"
//                   onClick={handleClick}
//                   style={{
//                     backgroundColor: "transparent",
//                     cursor: "not-allowed",
//                   }}
//                 ></div>
//               )} */}
//             </div>
//           </div>
//           <div className="w-1/6 flex flex-col items-center p-6">
//             <div className="mb-4">
//               {image ? (
//                 <img
//                   src={image}
//                   alt="Uploaded"
//                   className="w-32 h-32 rounded-full object-cover"
//                 />
//               ) : (
//                 <div className="w-32 h-32 rounded-full bg-gray-200 flex items-center justify-center">
//                   <span className="text-gray-500">Upload Picture</span>
//                 </div>
//               )}
//             </div>
//             <label
//               htmlFor="file-upload"
//               className="bg-customBlue text-white px-4 py-2 rounded-lg cursor-pointer"
//             >
//               Upload Picture
//             </label>
//             <input
//               id="file-upload"
//               type="file"
//               accept="image/*"
//               onChange={handleImageUpload}
//               className="hidden"
//             />
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default CreateManager;



import React, { useEffect, useState } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import {
  createUser,
  getLocationsByOrganisation,
} from "../../apiFunction/ApiFunctions";
import FooterComponent from "../admin/shared/FooterComponent";
import HeaderComponent from "../admin/shared/HeaderComponent";

const CreateUserPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [name, setName] = useState("");
  const [role, setRole] = useState("OrganisationAdministrator");
  const [department, setDepartment] = useState(
    state ? state.selectedLocation.id : ""
  );
  const [phone, setPhone] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [image, setImage] = useState(null);
  const [locations, setLocations] = useState(state ? state.locations : null);
  const [isLoading, setIsLoading] = useState(false);
  // console.log("state", state);

  useEffect(() => {
    if (locations === null) {
      getLocationsByOrganisation()
        .then((data) => {
          console.log("locations", data);
          setLocations(data);
        })
        .catch((error) => {
          console.error("Error fetching locations:", error);
        });
    }
  }, [locations]);

  const onOptionChange = (e) => {
    setRole(e.target.value);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        console.log(reader.result.split(",")[1]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    // Prepare the form data
    const user = {
      location_id: +department,
      name,
      phone_number: phone,
      picture: image ? image.split(",")[1] : null,
      email_address: username,
      password,
      roles: [role],
    };

    console.log(user);
    createUser(user)
      .then((data) => {
        console.log("user", data);
        setIsLoading(false);
        navigate(-1, { state });
      })
      .catch((error) => {
        console.error("Error creating user:", error);
      });

    // Check if any previous data is already there or not
    // const sessionNewUser = JSON.parse(sessionStorage.getItem("newUser"));

    // let updatedFormData;
    // if (
    //   sessionNewUser !== null &&
    //   !Array.isArray(sessionNewUser) &&
    //   typeof sessionNewUser === "object"
    // ) {
    //   updatedFormData = [sessionNewUser, formData];
    // } else if (Array.isArray(sessionNewUser)) {
    //   updatedFormData = [...sessionNewUser, formData];
    //   console.log(updatedFormData);
    // } else {
    //   updatedFormData = [formData];
    // }

    // Save the form data to session storage
    // sessionStorage.setItem("newUser", JSON.stringify(updatedFormData));

    // Log the form data
    // console.log("Form Data as JSON:", JSON.stringify(updatedFormData, null, 2));

    // Navigate to homescreen
    // navigate("/userlist", { state });
    // Handle form submission
  };

  return (
    // <div className="min-h-screen bg-gray-100 flex flex-col">
    <div className="min-h-screen bg-white flex flex-col">
      <HeaderComponent />
      <div className="container mx-auto">
        <h1 className="text-3xl font-medium text-customBlue mb-8 ml-[-2%]">
          Create User
        </h1>
        <form
          className="bg-white shadow-md rounded-lg px-8 flex mb-12"
          onSubmit={handleSubmit}
        >
          <div className="w-5/6 pr-8">
            <div className="mb-4">
              <label className="block text-gray-700">Name:</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full border p-2 rounded mt-1"
                required
              />
            </div>

            <div className="mb-4 w-1/3">
              <label className="block text-gray-700">Location:</label>
              <select
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
                className="w-full border p-2 rounded mt-1"
              >
                <option value="">Select</option>
                {locations &&
                  locations.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>
                  ))}
                {/* <option value="">Department</option>
                <option value="HR">Care Home 1</option>
                <option value="Finance">Care Home 2</option>
                <option value="Operations">Care Home 3</option> */}
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Phone Number:</label>
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="w-full border p-2 rounded mt-1"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Email Address:</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="w-full border p-2 rounded mt-1"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Password:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full border p-2 rounded mt-1"
              />
            </div>
            <div className="mb-4">
              <h3 className="text-2xl mb-4">Roles:</h3>
              <div className="mb-5 ">
                <input
                  type="radio"
                  value="OrganisationAdministrator"
                  checked={role === "OrganisationAdministrator"}
                  onChange={onOptionChange}
                />
                <label className="ml-3 text-gray-700" htmlFor="regular">
                  Organisation Administrator
                </label>
              </div>

              <div className="mb-5 ">
                <input
                  type="radio"
                  value="Manager"
                  checked={role === "Manager"}
                  onChange={onOptionChange}
                />
                <label className="ml-3 text-gray-700" htmlFor="medium">
                  Manager
                </label>
              </div>

              <div className="mb-5 ">
                <input
                  type="radio"
                  value="Specialist"
                  checked={role === "Specialist"}
                  onChange={onOptionChange}
                />
                <label className="ml-3 text-gray-700" htmlFor="large">
                  Specialist
                </label>
              </div>

              <div className="mb-5 ">
                <input
                  type="radio"
                  value="DementiaSpecialist"
                  checked={role === "DementiaSpecialist"}
                  onChange={onOptionChange}
                />
                <label className="ml-3 text-gray-700" htmlFor="large">
                  Dementia Specialist
                </label>
              </div>

              <div className="mb-5 ">
                <input
                  type="radio"
                  value="Nurse"
                  checked={role === "Nurse"}
                  onChange={onOptionChange}
                />
                <label className="ml-3 text-gray-700" htmlFor="large">
                  Nurse
                </label>
              </div>

              <div className="mb-5 ">
                <input
                  type="radio"
                  value="NursePractioner"
                  checked={role === "NursePractioner"}
                  onChange={onOptionChange}
                />
                <label className="ml-3 text-gray-700" htmlFor="large">
                  Nurse Practioner
                </label>
              </div>

              <div className="mb-5 ">
                <input
                  type="radio"
                  value="DementiaNurse"
                  checked={role === "DementiaNurse"}
                  onChange={onOptionChange}
                />
                <label className="ml-3 text-gray-700" htmlFor="large">
                  Dementia Nurse
                </label>
              </div>
            </div>
            <div className="flex justify-center mb-4">
              <button
                type="submit"
                className={`bg-blue-950 text-white text-xl py-2 px-8 rounded-lg mt-2`}
              >
                {!isLoading && "Create"}
                {isLoading && (
                  <div role="status" className="ml-[]">
                    <svg
                      aria-hidden="true"
                      className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </button>
            </div>
          </div>
          <div className="w-1/6 flex flex-col items-center">
            <div className="mb-4">
              {image ? (
                <img
                  src={image}
                  alt="Uploaded"
                  className="w-32 h-32 rounded-full object-cover"
                />
              ) : (
                <div className="w-32 h-32 rounded-full bg-gray-200 flex items-center justify-center">
                  <span className="text-gray-500">Upload Picture</span>
                </div>
              )}
            </div>
            <label
              htmlFor="file-upload"
              className="bg-customBlue text-white px-4 py-2 rounded-lg cursor-pointer"
            >
              Upload Picture
            </label>
            <input
              id="file-upload"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="hidden"
            />
          </div>
        </form>
      </div>
      {/* <FooterComponent path={-1} /> */}
    </div>
  );
};

export default CreateUserPage;
